<template>
  <div>
    <div>
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"

        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs.refClientsList.refresh()"
      >
        <template #table>
          <b-table
            slot="table"
            ref="refClientsList"
            striped
            hover
            :items="myProvider"
            :fields="visibleFields"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(amount)="data">
              <div>$ {{ data.item.amount }}</div>
            </template>
            <template #cell(created)="data">
              <div>{{ data.item.user_name }}</div>
              <div>{{ data.item.created_at | myGlobalWithHour }}</div>
            </template>
            <template #cell(actions)="data">
              <b-button
                v-if="currentTab == 1"
                v-b-tooltip.hover
                variant="primary"
                size="sm"
                title="generate transaction"
                @click="openGenerateTransaction(data.item.lead_id, data.item.id)"
              ><feather-icon
                icon="CreditCardIcon"
                class="mr-0"
              /></b-button>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </div>

    <generate-transaction
      v-if="generateTransactionOn"
      :lead_id="lead_id"
      :initial-payment-id="initialPaymentId"
      @refresh="$refs.refClientsList.refresh()"
      @close="closeGenerateTransaction"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InitialPayment from '@/views/administration/views/initial-payment/service/initial-payment.service'
import GenerateTransaction from '@/views/administration/views/initial-payment/GenerateTransaction.vue'

// Data

// Services

// Components

export default {
  components: {
    GenerateTransaction,
  },

  data() {
    return {
      generateTransactionOn: false,
      lead_id: null,
      initialPaymentId: null,
      isSortDirDesc: true,
      sortBy: '',
      isBusy: false,
      items: [],
      fields: [
        {
          key: 'client_name',
          label: 'Client',
          sortable: false,
          visible: true,
        },
        {
          key: 'program',
          label: 'Program',
          sortable: false,
          visible: true,
        },
        {
          key: 'card_number',
          label: 'Card Number',
          sortable: false,
          visible: true,
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: false,
          visible: true,
        },
        {
          key: 'date',
          label: 'Date',
          sortable: false,
          visible: true,
        },
        {
          key: 'created',
          label: 'Created',
          sortable: false,
          visible: true,
        },
        {
          key: 'actions',
          label: 'Actions',
          visible: true,
        },
      ],
      filters: [
        {
          type: 'datepicker',
          margin: true,
          showLabel: true,
          label: 'From',
          placeholder: 'Date',
          class: 'font-small-3',
          model: null,
          locale: 'en',
          dateFormatOptions: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },
          cols: 6,
        },
        {
          type: 'datepicker',
          margin: true,
          showLabel: true,
          label: 'To',
          placeholder: 'Date',
          class: 'font-small-3',
          model: null,
          locale: 'en',
          dateFormatOptions: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },
          cols: 6,
        }],
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'User...',
        model: '',
      },

      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      toPage: 0,
      totalRows: 0,

      tabIndex: null,
      payments: null,

      administrators: [],
      programs: [],
      states: [],
      program: null,
      state: null,
      text: null,
      start_page: '',
      perpage: '',
      claimSelected: null,
      claimTitle: '',
      accountId: null,
      claimCategory: null,
      claimId: '',
      modalClaimsSolution: false,
      statusClaim: null,
      countersClaims: [],
      accountSelected: null,
      currentSubTab: 4,
      // Modals
      showModalViewClaims: false,
      showModalClaimFiles: false,
      nextPage: null,
      endPage: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module
    },
    currentTab() {
      return this.$route.meta.currentTab
    },
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },

  },

  created() {
    this.fields[6].visible = this.$route.meta.currentTab === 1
  },
  methods: {

    closeGenerateTransaction() {
      this.generateTransactionOn = false
    },
    openGenerateTransaction(lead_id, initialPaymentId) {
      this.lead_id = lead_id
      this.initialPaymentId = initialPaymentId
      this.generateTransactionOn = true
    },
    async myProvider(ctx) {
      try {
        const params = {
          status: this.currentTab,
          perpage: ctx.perPage,
          page: ctx.currentPage,
          user: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
        }
        const res = await InitialPayment.getTransactionsRequest(params)
        this.items = res.data

        this.startPage = res.from
        this.paginate.currentPage = res.current_page
        this.paginate.perPage = res.per_page
        this.totalRows = res.total
        this.toPage = res.to
        this.nextPage = this.startPage + 1
        this.endPage = res.last_page

        return this.items || []
      } catch (error) {
        this.isBusy = false

        console.log(error)
        return []
      }
    },

  },
}
</script>
