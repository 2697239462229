<template>
  <b-modal
    v-model="ownModal"
    title="Generate Transaction"
    header-class="b-vue-modal-header"
    scrollable
    @hidden="close"
  >
    <b-container class="p-0">
      <b-form @submit.prevent="">
        <b-form-group
          label="Transaction Id"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="transaction_id"
            type="text"
          />
        </b-form-group>
        <b-form-group
          label="Amount"
          label-for="input-2"
        >
          <money
            v-model="amount"
            v-bind="{
              decimal: '.',
              thousands: ',',
              prefix: '$',
              precision: 2,
            }"
            class="form-control"
          />
        </b-form-group>
      </b-form>
    </b-container>
    <template #modal-footer>
      <b-button
          :disabled="spinner"
        variant="info"
        class="rounded"
        @click="generateTransaction()"
      >Save<b-spinner
        v-if="spinner"
        class="ml-1"
        small
      /> </b-button>
    </template>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex'

import InitialPaymentService from '@/views/administration/views/initial-payment/service/initial-payment.service'

export default {
  props: {
    lead_id: {
      type: Number,
      default: null,
    },
    initialPaymentId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      spinner: false,
      ownModal: true,
      transaction_id: '',
      amount: 0,

    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

  },

  methods: {

    async generateTransaction() {
      try {
        const params = {
          lead_id: this.lead_id,
          transaction_id: this.transaction_id,
          amount: this.amount,
          id: this.initialPaymentId,
        }
        this.spinner = true
        const response = await InitialPaymentService.generateTransaction(params)

        if (response.status === 500) {
          this.spinner = false
          this.$swal({
            title: 'Warning',
            text: response.message,
            icon: 'warning',
            timer: 2000,
            showConfirmButton: false,
          })

          this.$emit('refresh')
          this.close()
        }
        this.spinner = false
        this.$emit('refresh')
        this.close()
      } catch (error) {
        this.spinner = true
        console.log('error', error)
        this.$swal({
          title: 'Error',
          text: 'Error creating relationship',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        })
      }
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>
